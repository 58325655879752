import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ImsiComponent } from './imsi.component';

const routes: Routes = [
  {
    path: '',
    component: ImsiComponent,
    data: {
      title: 'IMSI manager',
    },
    children: [
      {
        path: '',
        redirectTo: 'provisionning-imsi',
        pathMatch: 'full',
      },
      {
        path: 'provisionning-imsi',
        loadChildren: () =>
          import('./provisionning-imsi/provisionningImsi.module').then(
            (m) => m.ProvImsiModule
          ),
      },
      {
        path: 'details-imsi',
        loadChildren: () =>
          import('./details-imsi/detailsImsi.module').then(
            (m) => m.DetailsImsiModule
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./personne-notif/personne-notif.module').then(
            (m) => m.PersonneNotifModule
          ),
      },
      {
        path: 'seuil_alerte',
        loadChildren: () =>
          import('./seuil-alerte/seuil-alerte.module').then(
            (m) => m.SeuilAlerteModule
          ),
      },
      {
        path: 'categorie',
        loadChildren: () =>
          import('./categorie/categorie.module').then((m) => m.CategorieModule),
      },
      {
        path: 'traitement_masse',
        loadChildren: () => 
          import('./traitement-masse/traitement-masse.module').then((m) => m.TraitementMasseModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ImsiRoutingModule {}
