import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imsi',
  templateUrl: './imsi.component.html',
  styleUrls: ['./imsi.component.scss']
})
export class ImsiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
