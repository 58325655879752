<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between top">
    <h1 mat-dialog-title>Pool d'adresse IP</h1>
    <button class="close text-white" mat-button (click)="onNoClick()">X</button>
</div>
<div mat-dialog-content>
    <form [formGroup]="formModal" (ngSubmit)="onSubmit(formModal.value)" class="form">
        <div fxLayout="row wrap" fxLayoutGap="10px">
            <mat-form-field appearance="standard" fxFlex="100">
                <mat-label class="text-white">Type client</mat-label>
                <mat-select formControlName="idTypeClient" name="idTypeClient" placeholder="Veuillez choisir un type de client">
                    <mat-option *ngFor="let item of listeTypeClient" [value]="item.id">{{item.libelle}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formModal.controls.idTypeClient.invalid">veuillez saisir le type de client</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex="100">
                <mat-label class="text-white">Service</mat-label>
                <mat-select formControlName="idService" name="idService" placeholder="Veuillez choisir un service">
                    <mat-option *ngFor="let item of listeService" [value]="item.id">{{item.libelle}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formModal.controls.idService.invalid">veuillez saisir le service</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex="100">
                <mat-label class="text-white">Zone</mat-label>
                <mat-select formControlName="idZone" name="idZone" placeholder="Veuillez choisir une zone">
                    <mat-option *ngFor="let item of listeZone" [value]="item.id">{{item.libelle}}</mat-option>
                </mat-select>
                <mat-error *ngIf="formModal.controls.idZone.invalid">veuillez saisir la zone</mat-error>
            </mat-form-field>
            <!-- <mat-form-field appearance="standard" fxFlex="100">
                <mat-label class="text-white">Adresse</mat-label>
                <input matInput pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(0)$" formControlName="adresse" name="adresse">
                <mat-error *ngIf="formModal.controls.adresse.errors?.required">veuillez saisir l'adresse</mat-error>
                <mat-error *ngIf="formModal.controls.adresse.errors?.pattern">Format IP invalide Ex: 160.230.021.0</mat-error>
                
            </mat-form-field>
            <mat-form-field appearance="standard" fxFlex="100">
                <mat-label class="text-white">Masque</mat-label>
                <input matInput mask="IP" formControlName="masque" name="masque"
                pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$">
                <mat-error *ngIf="formModal.controls.masque.errors?.required">veuillez saisir le masque</mat-error>
                <mat-error *ngIf="formModal.controls.masque.errors?.pattern">Format IP invalide Ex: 160.230.021.0</mat-error>
            </mat-form-field> -->
        </div>
    </form>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button color="warn" (click)="onNoClick()">Annuler</button>
    <button mat-raised-button color="primary" type="submit" class="button"
    [disabled]="!formModal.valid" (click)="onSubmit(formModal.value)">Valider</button>
    <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Valider</button> -->
</div>