import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ip-manager',
  templateUrl: './ip-manager.component.html',
  styleUrls: ['./ip-manager.component.scss']
})
export class IpManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
