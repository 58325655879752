import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { RouterModule } from '@angular/router';

import { ImsiRoutingModule } from './imsi-routing.module';
import { ImsiComponent } from './imsi.component';
import { CategorieComponent } from './categorie/categorie.component';

@NgModule({
  declarations: [ImsiComponent],
  imports: [
    CommonModule,
    ImsiRoutingModule,
    //RouterModule
  ],
})
export class ImsiModule {}
